import DOMPurify from 'dompurify'

export const getCWPEnvironmentForURL = (function () {
  const environments = [
    {
      name: 'localhost:3000',
      pattern: /^(http|https):?\/\/localhost:3000/,
    },
    {
      name: 'localhost:8080',
      pattern: /^(http|https):?\/\/localhost:8080\/site\/joinameriprise/,
    },
    {
      name: 'localhost:8081',
      pattern: /^(http|https):?\/\/localhost:8081\/site\/joinameriprise/,
    },
    {
      name: 'localhostcms',
      pattern: /^(http|https):?\/\/localhost:(8080|8081)\/site\/_cmsinternal\/joinameriprise/,
    },
    {
      name: 'localhost:8080cms',
      pattern: /^(http|https):?\/\/localhost:8080\/site\/_cmsinternal\/joinameriprise/,
    },
    {
      name: 'localhost:8081cms',
      pattern: /^(http|https):?\/\/localhost:8081\/site\/_cmsinternal\/joinameriprise/,
    },
    {
      name: 'localhostpreview',
      pattern: /^(http|https):?\/\/localhost:(8080|8081)\/site\/joinameriprise\/preview/,
    },
    {
      name: 'devcms',
      pattern: /^(http|https):?\/\/cms-ws\d*\.dev\.ameriprise.com/,
    },
    {
      name: 'dev',
      pattern: /^(http|https):?\/\/www-ws\d*\.dev\.ameriprise.com\/careers/,
    },
    {
      name: 'devpreview',
      pattern: /^(http|https):?\/\/www-ws\d*\.dev\.ameriprise.com\/careers\/preview/,
    },
    {
      name: 'qawscms',
      pattern: /^(http|https):?\/\/cms-ws\d*\.qa\.ameriprise.com/,
    },
    {
      name: 'qaws',
      pattern: /^(http|https):?\/\/www-ws\d*\.qa\.ameriprise.com\/careers/,
    },
    {
      name: 'qawspreview',
      pattern: /^(http|https):?\/\/www-ws\d*\.qa\.ameriprise.com\/careers\/preview/,
    },
    {
      name: 'qacms',
      pattern: /^(http|https):?\/\/cms\.qa\.ameriprise.com/,
    },
    {
      name: 'qa',
      pattern: /^(http|https):?\/\/www\.qa\.ameriprise.com\/careers/,
    },
    {
      name: 'qapreview',
      pattern: /^(http|https):?\/\/www\.qa\.ameriprise.com\/careers\/preview/,
    },
    {
      name: 'prodcms',
      pattern: /^(http|https):?\/\/cms\.ameriprise.com/,
    },
    {
      name: 'prod',
      pattern: /^(http|https):?\/\/www\.ameriprise.com\/careers/,
    },
    {
      name: 'prodpreview',
      pattern: /^(http|https):?\/\/www\.ameriprise.com\/careers\/preview/,
    },
  ]

  return function getCWPEnvironmentForURL(url) {
    url = url || window.location.toString()

    let result

    environments.forEach(function (environment) {
      if (environment.pattern.test(url)) result = environment.name
    })

    result = result || 'prod'
    return result
  }
})()

export const APIENDPOINT = env => {
  let base = ''
  let baseGallery = ''
  let menuBase = ''
  let menuBaseWithParams = ''
  let advisor = ''
  switch (env) {
    case 'localhost:8080':
      base = 'http://localhost:8080/site/joinameriprise/'
      baseGallery = '/site/binaries/'
      menuBase = 'http://localhost:8080/site/cfgapi/hst/menus/local-joinameriprise/'
      menuBaseWithParams = 'http://localhost:8080/site/cfgapi/hst/menus-ext/local-joinameriprise/'
      advisor = 'https://www.qa.ameriprise.com/api/advisorsv22/json/'
      break
    case 'localhost:8080cms':
      base = 'http://localhost:8080/site/_cmsinternal/joinameriprise/'
      baseGallery = '/site/binaries/'
      menuBase = 'http://localhost:8080/site/_cmsinternal/cfgapi/hst/menus/local-joinameriprise/'
      menuBaseWithParams =
        'http://localhost:8080/site/_cmsinternal/cfgapi/hst/menus-ext/local-joinameriprise/'
      break
    case 'localhost:8081cms':
      base = 'http://localhost:8081/site/_cmsinternal/joinameriprise/'
      baseGallery = '/site/binaries/'
      menuBase = 'http://localhost:8081/site/cfgapi/hst/menus/local-joinameriprise/'
      menuBaseWithParams = 'http://localhost:8081/site/cfgapi/hst/menus-ext/local-joinameriprise/'
      break
    case 'localhostpreview':
      base = 'http://localhost:8080/site/joinameriprise/preview/'
      baseGallery = '/site/binaries/'
      menuBase = 'http://localhost:8080/site/cfgapi/hst/menus/local-joinameriprise/preview/'
      menuBaseWithParams =
        'http://localhost:8080/site/cfgapi/hst/menus-ext/local-joinameriprise/preview/'
      advisor = 'https://www.qa.ameriprise.com/api/advisorsv22/json/'
      break
    case 'localhost:8081':
      base = 'http://localhost:8081/site/joinameriprise/'
      baseGallery = '/site/binaries/'
      menuBase = 'http://localhost:8081/site/cfgapi/hst/menus/local-joinameriprise/'
      menuBaseWithParams = 'http://localhost:8081/site/cfgapi/hst/menus-ext/local-joinameriprise/'
      advisor = 'https://www.qa.ameriprise.com/api/advisorsv22/json/'
      break
    case 'localhost:3000':
      base = 'https://www.ameriprise.com/careers/'
      baseGallery = 'https://www.ameriprise.com/careers/binaries/'
      menuBase = 'https://www.ameriprise.com/cfgapi/hst/menus/joinameriprise/'
      menuBaseWithParams = 'https://www.ameriprise.com/cfgapi/hst/menus-ext/joinameriprise/'
      break
    case 'devcms':
    case 'qawscms':
    case 'qacms':
    case 'prodcms':
      base = '/site/_cmsinternal/careers/'
      baseGallery = '/site/_cmsinternal/binaries/'
      menuBase = '/site/_cmsinternal/cfgapi/hst/menus/joinameriprise/'
      menuBaseWithParams = '/site/_cmsinternal/cfgapi/hst/menus-ext/joinameriprise/'
      break
    case 'dev':
      base = '/careers/'
      baseGallery = '/binaries/'
      menuBase = '/cfgapi/hst/menus/joinameriprise/'
      menuBaseWithParams = '/cfgapi/hst/menus-ext/joinameriprise/'
      advisor = 'https://www.qa.ameriprise.com/api/advisorsv22/json/'
      break
    case 'qaws':
    case 'qa':
    case 'prod':
      base = '/careers/'
      baseGallery = '/binaries/'
      menuBase = '/cfgapi/hst/menus/joinameriprise/'
      menuBaseWithParams = '/cfgapi/hst/menus-ext/joinameriprise/'
      advisor = '/api/advisorsv22/json/'
      break
    case 'devpreview':
    case 'qawspreview':
    case 'qapreview':
      base = '/careers/preview/'
      baseGallery = '/binaries/'
      menuBase = '/cfgapi/hst/menus/joinameriprise-preview/'
      menuBaseWithParams = '/cfgapi/hst/menus-ext/joinameriprise-preview/'
      advisor = 'https://www.qa.ameriprise.com/api/advisorsv22/json/'
      break
  }
  return { base, baseGallery, menuBase, menuBaseWithParams, advisor }
}

const CONSTs = {
  PRODUCTION: 'production',
  QA: 'qa',
  DEV: 'dev',
  LOCAL: 'local',
  PREVIEW: 'preview',
  UNKNOWN: 'unknown',
  POPOVER_CLASS: 'Popover',
  IS_VISIBLE_CLASS: 'is-visible',
}

export function getEnvironment() {
  const isLocal =
    /localhost|127\.0\.0\.1/i.test(window.location.toString()) || window.location.port === '8081'
  const isAmeriprisePublicSite =
    /(www|www1)(\d*|-ws1|preview|demo)?(\.qa.stage|\.qa|.dev|)?\.ameriprise\.com/.test(
      window.location.toString()
    )
  const isAnyLower =
    /dev|ws1.dev|qa|ws1.qa|qa.stage|preview|127\.0\.0\.1|local/i.test(window.location.toString()) ||
    window.location.port === '8081'
  const isAmpLower = isAmeriprisePublicSite && isAnyLower
  const isProd = !isAnyLower && !isAmpLower
  const isDev = /dev|ws1.dev\./i.test(window.location.toString())
  const isQa = /qa|ws1.qa\./i.test(window.location.toString())
  const isPreview = /preview\./i.test(window.location.toString())

  switch (true) {
    case isProd:
      return CONSTs.PRODUCTION
    case isLocal:
      return CONSTs.LOCAL
    case isQa:
      return CONSTs.QA
    case isDev:
      return CONSTs.DEV
    case isPreview:
      return CONSTs.PREVIEW
    default:
      return CONSTs.UNKNOWN
  }
}

export const getNavItems = (halItems, showOptOut) => {
  const navItems = halItems || []
  if (showOptOut) {
    return navItems
  }
  return filterOptOut(navItems)
}

export const filterOptOut = navItems => {
  return navItems.filter(currMenu => {
    // in next release I will change how this is done to use resource bundle
    return currMenu.text !== 'Do Not Sell or Share My Personal Data'
  })
}

export function decodeHtml(html) {
  var txt = document.createElement('textarea')
  txt.innerText = DOMPurify.sanitize(html,{ ADD_ATTR: ['target'] })
  return txt.value
}

export function decodeEntities(encodedString) {
  var translateRe = /&(nbsp|amp|quot|lt|gt);/g
  var translate = {
    nbsp: ' ',
    amp: '&',
    quot: "'",
    lt: '<',
    gt: '>',
  }
  return encodedString
    .replace(translateRe, function (match, entity) {
      return translate[entity]
    })
    .replace(/&#(\d+);/gi, function (match, numStr) {
      var num = parseInt(numStr, 10)
      return String.fromCharCode(num)
    })
}

export function sanitizeHTML(data) {
  return DOMPurify.sanitize(decodeEntities(decodeHtml(data)), { ADD_ATTR: ['target'] })
}

export const fetchAPIKeys = async () => {
  let API_HTMLPDF_KEY, GOOGLE_API_KEY
  const env = getCWPEnvironmentForURL(window.location.href)
  const url = `${APIENDPOINT(env).base}halapi/resourcebundles/joinameriprise.apikeys`
  try {
    const response = await fetch(url)
    const data = await response.json()
    if (data && data.bundles && data.bundles.default) {
      API_HTMLPDF_KEY = data.bundles.default['API_HTMLPDF_KEY']
      GOOGLE_API_KEY = data.bundles.default['GOOGLE_API_KEY']
    }
  } catch (error) {}
  return { API_HTMLPDF_KEY, GOOGLE_API_KEY }
}

