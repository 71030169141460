/**
 * Fetch JSON for the given URL
 *
 * @param {String} Url to fetch
 * @returns {Promise} Resolving with JSON from the request
 */
export function fetchJSON (url) {
  return fetch(url, {
    mode: 'cors',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }
  })
    .then(res => res.json())
}

/**
 * Fetch Response for the given URL
 *
 * @param {String} Url to fetch
 * @returns {Promise} Resolving with response from the request
 */

export function ampFetch (url) {
  return window.fetch(url)
    .then(response => {
      if (!response.ok) {
        throw new Error(response.statusText)
      }
      return response
    })
}

/*
 * Fetch POST data to the given URL
 * postDataToService({
 *     url: '/email/api',
 *     data: {}
 * }, callbackFn)
 *
 * @param {Object} with url and data to post
 * @returns response in the callback method
 */
export function postDataToService (args) {
  let headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  }
  if (args.headers) {
    headers = {
      ...headers,
      ...args.headers
    }
  }
  return fetch(args.url, {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(args.data)
  })
    .then(
      (response) => {
        return response.json()
      }
    )
}
