import React from 'react';
import PropTypes from 'prop-types';
import { SimpleContent as SimpleContentComponent } from '@cwp/amp-microsites/build/components.es.js';
import './simple-content.scss';

const SimpleContent = (props) => {
  const {
    title,
    intro,
    content,
    brandExtension,
    bgColor,
    vertSpacing,
    link,
    publishDate,
    publishTime,
    removeSpacing
  } = props.content;

  return (
    <SimpleContentComponent
      title={title}
      intro={intro}
      content={content}
      brandExtension={brandExtension}
      vertSpacing={vertSpacing}
      bgColor={bgColor}
      button={link}
      publishDate={publishDate}
      publishTime={publishTime}
      removeSpacing={removeSpacing}
    />
  );
};
SimpleContent.propTypes = {
  content: PropTypes.object
};
export default SimpleContent;
