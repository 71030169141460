/* eslint-disable react/display-name, react/no-find-dom-node */

import CwpComponent from '../components/Component.js'
import React from 'react'
import ReactDOM from 'react-dom'

/**
 * Use a react component as a cwp component
 *
 * @param {React.Component} ReactComponent
 * @example
 * const CwpComponent = reactToCwp(MyReactComponent)
 *
 * new CwpComponent(element, someProps)
 */
export function reactToCwp (ReactComponent) {
  return class extends CwpComponent {
    constructor (element, props) {
      super(element, props)

      ReactDOM.render(
        <ReactComponent {...props} />,
        element
      )
    }
  }
}

/**
 * Use a cwp component inside a react component
 *
 * @param {CwpComponent} CwpComponent
 * @example
 * const ReactComponent = cwpToReact(MyCwpComponent)
 *
 * return (
 *   <div>
 *     <ReactComponent {...someProps} />
 *   </div>
 * )
 */
export function cwpToReact (CwpComponent) {
  return class extends React.Component {
    componentDidMount () {
      this._instance = new CwpComponent(
        ReactDOM.findDOMNode(this),
        this.props
      )
    }

    componentDidUpdate () {
      this._instance.props = this.props
      this._instance._render()
    }

    render () {
      return <div></div>
    }
  }
}
