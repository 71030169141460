export function sleep (time = 0) {
  return new Promise(resolve => {
    setTimeout(resolve, time)
  })
}

export async function nextTick (fn) {
  await sleep(0)
  return fn()
}
