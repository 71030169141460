import loadComponents, { loadedClass } from './loader.js'

export { loadedClass }

// eslint-disable-next-line no-undef
let componentObserver = new MutationObserver(loadComponents)

componentObserver.observe(document.body, {
  childList: true,
  subtree: true
})

document.addEventListener('DOMContentLoaded', loadComponents)

// Make function available to reload components externally
window.loadComponents = loadComponents
