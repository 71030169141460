import React from 'react';
import PropTypes from 'prop-types';
import { getCWPEnvironmentForURL, APIENDPOINT } from '../../../common/utils/common-utils';
import { Commitments } from '@cwp/amp-microsites/build/components.es.js';

const CommitmentsTab = (props) => {
  const { content, testId } = props;

  const env = getCWPEnvironmentForURL(window.location.href);

  return (
    <div>
      <Commitments
        content={content}
        testId={testId}
        svgUrl={`${APIENDPOINT(env).baseGallery}content/gallery/ampmicrosites/brand-icons`}
      />
    </div>
  );
};
CommitmentsTab.propTypes = {
  testId: PropTypes.string,
  content: PropTypes.shape({
    headline: PropTypes.string,
    tab: PropTypes.arrayOf(
      PropTypes.shape({
        identifier: PropTypes.string,
        buttonId: PropTypes.string,
        title: PropTypes.string,
        icon: PropTypes.shape({
          iconsDropdown: PropTypes.string,
          iconsColorsDropDown: PropTypes.string,
          stylesDropDown: PropTypes.string,
          size: PropTypes.string,
          enableBorder: PropTypes.string,
          identifier: PropTypes.string
        }),
        location: PropTypes.string,
        bgcolor: PropTypes.string,
        colorTemplate: PropTypes.string,
        analytics: PropTypes.string,
        content: PropTypes.shape({
          heading: PropTypes.string,
          body: PropTypes.string,
          imageSrc: PropTypes.string,
          imageAlt: PropTypes.string,
          link: PropTypes.string,
          linkText: PropTypes.string
        })
      })
    )
  })
};
export default CommitmentsTab;
