import React from 'react'
import './hero-topic-front.scss'
import { APIENDPOINT, getCWPEnvironmentForURL, sanitizeHTML } from '../../../../common/utils/common-utils'

const HeroTopicFront = props => {
  const { content, testId } = props
  const {
    heading,
    body,
    image,
    textAlignment,
    color,
    buttonAlignment,
    buttonHref,
    buttonTarget,
    buttonText,
    analytics,
  } = content

  const env = getCWPEnvironmentForURL(window.location.href)
  const largeFacet = `${
    APIENDPOINT(env).baseGallery
  }content/gallery/ampmicrosites/facets/hero-facets-${color || 'midnight-blue'}.svg`
  const mobileFacet = `${
    APIENDPOINT(env).baseGallery
  }content/gallery/ampmicrosites/facets/hero-facets-mobile-${color || 'midnight-blue'}.svg`

  let bgClassName = ''
  switch (color) {
    case 'light-blue':
      bgClassName = 'u-bgColorLightBlue'
      break
    case 'purple':
      bgClassName = 'u-bgColorPurple'
      break
    case 'berry':
      bgClassName = 'u-bgColorBerry'
      break
    case 'mint':
      bgClassName = 'u-bgColorMint'
      break
    case 'dark-mint':
      bgClassName = 'u-bgColorDarkMint'
      break
    case 'orange':
      bgClassName = 'u-bgColorOrange'
      break
    case 'dark-grey':
      bgClassName = 'u-bgColorDarkGrey'
      break
    case 'midnight-blue':
    default:
      bgClassName = 'u-bgColorMidnightBlue'
  }

  return (
    <section
      className='HeroTopicFront--redesign u-paddingBottom2gu'
      data-analytics-container='Hero'
      data-analytics-component-template='Topic Front'
      data-analytics-component-name='Hero - Redesign'>
      <div className='u-posRelative'>
        <img
          className='HeroTopicFront-bgImage u-posAbsolute u-sm-hidden'
          src={largeFacet}
          alt=''
          data-testid={testId ? `${testId}-facet` : null}
        />
        <img
          className='HeroTopicFront-bgImage u-posAbsolute u-md-hidden u-lg-hidden'
          src={mobileFacet}
          alt=''
          data-testid={testId ? `${testId}-mobile-facet` : null}
        />
        <div
          className={`HeroTopicFront-contentBox u-flex u-sizeConstrained u-posRelative u-paddingHoriz10gu u-paddingVert10gu`}>
          <div
            className={`u-lg-size1of2 u-md-size1of2 u-sm-sizeFill Content u-flex u-flexAlignItemsCenter ${textAlignment} ${bgClassName}`}
            data-testid={testId ? `${testId}-box` : null}>
            <div className='u-paddingHoriz4gu u-paddingVert6gu'>
              <h1
                className='Type-1 u-textSecondary u-textColorWhite'
                data-testid={testId ? `${testId}-heading` : null}
                dangerouslySetInnerHTML={{ __html: sanitizeHTML(heading) }}></h1>
              <div
                className={`Content u-textColorWhite u-marginTop2gu ${textAlignment}`}
                dangerouslySetInnerHTML={{ __html: sanitizeHTML(body) }}
                data-testid={testId ? `${testId}-body` : null}
              />
              {buttonText && (
                <a
                  className={`Button Button--primary Button--reversed u-marginTop5gu ${buttonAlignment}`}
                  href={buttonHref}
                  target={buttonTarget}
                  data-analytics={analytics}>
                  {buttonText}
                </a>
              )}
            </div>
          </div>
          <div className='HeroTopicFront-contentImage u-sm-hidden u-md-size2of3 u-lg-size2of3 u-posRelative'>
            <img
              className='u-sizeFull'
              src={image}
              alt=''
              data-testid={testId ? `${testId}-image` : null}
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default HeroTopicFront
