import React from 'react';
import PropTypes from 'prop-types';
import { CardList, CardGrid, CardCarousel } from '@cwp/amp-microsites/build/components.es.js';
import './card-collection.scss';

const CardCollection = (props) => {
  const { template } = props.content;
  switch (template) {
    case 'card-list-nextGen':
      return <CardList {...props.content} />;
    case 'card-grid-nextGen':
      return (
        <CardGrid
          componentSpacing={props.content.componentSpacing}
          cardLimit={props.content.cardLimit}
          cardList={props.content.cardList}
          cardGridHeadline={props.content.headline}
          cardDescription={props.content.cardDescription}
          button={props.content.button}
          brandExtension={props.content.brandExtension}
        />
      );
    case 'card-carousel-nextGen':
      return (
        <CardCarousel
          componentSpacing={props.content.componentSpacing}
          cardList={props.content.cardList}
          cardCarouselHeadline={props.content.headline}
          brandExtension={props.content.brandExtension}
        />
      );
    default:
      return null;
  }
};
CardCollection.propTypes = {
  content: PropTypes.object
};
export default CardCollection;
