import React, { useEffect, useRef } from 'react'
import './hero-complex.scss'
import { APIENDPOINT, getCWPEnvironmentForURL, sanitizeHTML } from '../../../../common/utils/common-utils'

const ComplexHero = props => {
  const { content, testId } = props
  const {
    featuredImage,
    backgroundFacet,
    backgroundImage,
    textContent,
    buttonTarget,
    buttonUrl,
    buttonText,
    analytics,
    heading,
  } = content
  const headingRef = useRef(null)
  useEffect(() => {
    checkHeight()
    window.addEventListener('resize', checkHeight)
    return () => {
      window.removeEventListener('resize', checkHeight)
    }
  }, [])

  function checkHeight() {
    if (headingRef.current.clientHeight <= 53) {
      headingRef.current.classList.add('Type-Display')
    }
    if (headingRef.current.clientHeight > 102) {
      headingRef.current.classList.remove('Type-Display')
    }
  }

  const heroPattern = () => {
    if (backgroundFacet && featuredImage) {
      return 'facetCutout'
    }

    if (backgroundImage && !backgroundFacet) {
      return 'imageOverlay'
    }

    return 'illustration'
  }

  const env = getCWPEnvironmentForURL(window.location.href)

  return (
    <section
      className={`ComplexHero ComplexHero--${heroPattern()}`}
      data-analytics-container='Hero'>
      <div
        className={`u-flex u-sm-flexColReverse u-md-flexColReverse u-lg-paddingLeft3gu u-posRelative ${
          !backgroundFacet && !backgroundImage ? 'ComplexHero-border' : ''
        }`}>
        <div
          data-testid={testId ? `${testId}-background-content` : null}
          className={`ComplexHero-background u-posAbsolute ${
            !backgroundFacet && !backgroundImage ? 'ComplexHero-border' : ''
          }`}
          {...(backgroundImage && {
            style: { backgroundImage: `url(${backgroundImage})` },
          })}
          {...(backgroundFacet && {
            style: {
              backgroundImage: `url(${
                APIENDPOINT(env).baseGallery
              }content/gallery/ampmicrosites/facets/hero-facets-${
                backgroundFacet || 'midnight-blue'
              }.svg)`,
            },
          })}></div>
        <div className='ComplexHero-mobileOverlay u-lg-size6of12'>
          <div
            className={`ComplexHero-content u-sm-marginHoriz4gu u-md-marginHoriz4gu u-paddingHoriz3gu u-marginLeft3gu u-sm-bgColorWhite u-md-bgColorWhite 
                ${backgroundImage ? 'u-bgColorWhite u-borderColorNeutralHero' : ''}`}
            data-testid={testId ? `${testId}-content` : null}>
            <h1
              ref={headingRef}
              className={`u-textSecondary u-marginTop6gu u-textTight`}
              data-testid={testId ? `${testId}-heading` : null}>
              {heading}
            </h1>
            <div
              className='u-marginTop4gu'
              dangerouslySetInnerHTML={{ __html: sanitizeHTML(textContent) }}
              data-testid={testId ? `${testId}-body` : null}
            />
            <a
              className='Button Button--primary u-marginTop4gu u-sm-block u-marginBottom6gu'
              target={buttonTarget}
              href={buttonUrl}
              data-analytics={analytics}
              data-testid={testId ? `${testId}-link` : null}>
              {buttonText}
            </a>
          </div>
        </div>
        <div className={`ComplexHero-topWrapper u-flex u-flexJustifyCenter u-lg-size6of12`}>
          {featuredImage && (
            <div
              className={`ComplexHero-featuredImage`}
              style={{ backgroundImage: `url(${featuredImage})` }}
              data-testid={testId ? `${testId}-featured-image` : null}></div>
          )}
        </div>
      </div>
    </section>
  )
}

export default ComplexHero
