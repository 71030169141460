import React from 'react'
import './hero-section-front.scss'
import { APIENDPOINT, getCWPEnvironmentForURL, sanitizeHTML } from '../../../../common/utils/common-utils'

const HeroSectionFront = props => {
  const { content, testId } = props
  const {
    heading,
    body,
    image,
    imageMobile,
    textAlignment,
    facetColor,
    hideMobileImage,
    fontColor,
    contentModifier,
  } = content

  const env = getCWPEnvironmentForURL(window.location.href)
  const showMobileImage = hideMobileImage !== 'true'
  const largeFacet = `${
    APIENDPOINT(env).baseGallery
  }content/gallery/ampmicrosites/facets/hero-facets-${facetColor || 'midnight-blue'}.svg`
  const mobileFacet = `${
    APIENDPOINT(env).baseGallery
  }content/gallery/ampmicrosites/facets/hero-facets-mobile-${facetColor || 'midnight-blue'}.svg`

  return (
    <section
      className='HeroSectionFront'
      data-analytics-container='Hero'
      data-analytics-component-template='Section Front'
      data-analytics-component-name='Hero'>
      <img
        className='HeroSectionFront-facet u-sm-hidden'
        src={largeFacet}
        alt=''
        data-testid={testId ? `${testId}-facet` : null}
      />
      <img
        className='HeroSectionFront-facet u-md-hidden u-lg-hidden'
        src={mobileFacet}
        alt=''
        data-testid={testId ? `${testId}-facet-mobile` : null}
      />

      <div className='HeroSectionFront-content'>
        <div className='HeroSectionFront-heading u-sizeConstrained'>
          {heading && (
            <h1
              className={`Type-Display u-paddingTop10gu ${fontColor} ${textAlignment}`}
              dangerouslySetInnerHTML={{ __html: sanitizeHTML(heading) }}
              data-testid={testId ? `${testId}-heading` : null}></h1>
          )}
          {body && (
            <div
              className={`Content Content--large u-paddingTop2gu ${contentModifier} ${textAlignment}`}
              dangerouslySetInnerHTML={{ __html: sanitizeHTML(body) }}
              data-testid={testId ? `${testId}-body` : null}></div>
          )}
        </div>
        <div className='u-paddingBottom10gu u-sm-paddingHoriz3gu u-paddingTop10gu u-posRelative'>
          {image && (
            <img
              className='HeroSectionFront-featuredImage u-sm-hidden u-block u-md-sizeFull u-sizeConstrained'
              src={image}
              alt=''
              data-testid={testId ? `${testId}-image` : null}
            />
          )}
          {showMobileImage && (
            <img
              className='HeroSectionFront-featuredImage u-md-hidden u-lg-hidden u-block u-sizeConstrained'
              src={imageMobile}
              alt=''
              data-testid={testId ? `${testId}-image-mobile` : null}
            />
          )}
        </div>
      </div>
    </section>
  )
}

export default HeroSectionFront
